function readCookies() {
  const allCookies = document.cookie;
  let cookieFound = false;
  let name;
  let value;
  const cookieArray = allCookies.split(';');

  for (let i = 0; i < cookieArray.length; i += 1) {
    name = cookieArray[i].split('=')[0].replace(' ', '');
    value = cookieArray[i].split('=')[1];

    if (name === 'cookie-agreed' && value === '1') {
      cookieFound = true;
      break;
    }
  }

  return cookieFound;
}

if (!readCookies()) {
  $('.gdpr').css('display', 'grid');
}

$('body').on('click', '.gdpr-submit', () => {
  const CookieDate = new Date();
  CookieDate.setFullYear(CookieDate.getFullYear() + 1);
  document.cookie = 'cookie-agreed=1;expires='.concat(CookieDate.toGMTString(), ';path=/');
  $('.gdpr').fadeOut();
});
// # sourceMappingURL=cookies.min.js.map
